#content,
.content-block,
.statement-block,
.testimonial-block {
    position: relative;
    @include clearfix;
}

#content,
.content-block,
.statement-block {
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.statement-block {
    min-height: 92vh;
    box-sizing: border-box;
}

.content-block,
.film-block {
    padding: {
        top: 0;
        bottom: 20rem;
    }
    box-sizing: border-box;
}

.film-block {
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.content-block:last-of-type {
    padding: {
        bottom: 60rem;
    }
    margin: {
        bottom: -34rem;
    }
    box-sizing: content-box;
}

.testimonial-block:last-of-type {
    padding: {
        bottom: 40rem;
    }
    margin: {
        bottom: -34rem;
    }
    box-sizing: content-box;
}

.testimonial-block {
    text-align: center;
    height: auto;
    @include break(min,s) {
        text-align: center;
    }
    background-image: url("/assets/images/backgrounds/sunset.jpg");
    background-size: cover;
    background-position: bottom center;
    background-attachment: fixed;
    padding: {
        top: 6rem;
        bottom: 6rem;
    }

    .testimonial-section &
    {
      background-image: none;
      box-shadow: none;
    }
}

.content,
.statement {
    .moods &,
    .rights & {
        color: #fff;
    }
}

.film-related__films {
    list-style-type: none;
    clear: left;
    margin: 0;
    padding: 0;
    @include break(min,m) {
        width: 50%;
        float: left;
        clear: none;

        &.no-testimonials {
            width: 100%;
        }
    }
}

.film-related__films__film {
    @include break(min,s) {
        width: calc(50% - 1.5rem);
        float: left;

        &:nth-of-type(even) {
            margin: {
                left: 3rem;
            }
        }

        .no-testimonials & {
            width: calc(33.33% - 1.5rem);

            &:nth-of-type(even) {
                margin: {
                    left: 0;
                }
            }

            &:nth-of-type(3n+2) {
                margin: {
                    left: 2.25rem;
                    right: 2.25rem;
                }
            }
        }
        @include break(min,m) {
            .no-testimonials & {
                width: calc(25% - 3rem);
                margin: {
                    left: 1.5rem;
                    right: 1.5rem;
                }

                &:nth-of-type(even) {
                    margin: {
                        left: 1.5rem;
                    }
                }

                &:nth-of-type(3n+2) {
                    margin: {
                        left: 1.5rem;
                        right: 1.5rem;
                    }
                }

                &:first-of-type {
                    margin: {
                        left: 0;
                    }
                }

                &:last-of-type {
                    margin: {
                        right: 0;
                    }
                }

                &:nth-of-type(1n+4) {
                    margin-right: 0;
                }

                &:nth-of-type(1n+5) {
                    margin-left: 0;
                }
            }
        }
    }
    margin: {
        bottom: 3rem;
    }
    width: 100%;
    height: 16rem;
    position: relative;
    display: block;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;

    &__link {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        background-color: rgba(0,0,0,0.4);
    }
}

.film-related__films__film__title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-transform: uppercase;
    font-family: $heading__type;
}

.statement {
    @include break(min,s) {
        position: absolute;
        left: 0;
        top: 52vh;
        transform: translate(0,-50%);
        margin: {
            top: 0;
            bottom: 0;
        }
    }
    width: 100%;
    margin: {
        top: 10rem;
        bottom: 10rem;
    }
    color: #555;
}

.content {
    width: 100vw;
    left: 0;
    position: relative;
    color: #353535;
}

.statement-text {
    padding: {
        left: 3rem;
        right: 3rem;
        top: 6rem;
        bottom: 6rem;
    }
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: left;
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-family: $heading__type;
    @include break(min,s) {
        display: inline-block;
        width: calc( (70rem / 12) * 10);
        text-align: left;
        box-sizing: border-box;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
    @include break(min,m) {
        width: calc( (96rem / 12) * 8);
        top: 50%;
    }
    @include break(min,l) {
        width: calc( (120rem / 12) * 8);
    }
}

.statement-text__heading {
    font-size: 2.4rem;
    line-height: 3.2rem;
    @include break(min,s) {
        font-size: 4rem;
        line-height: 4rem;
    }
    @include break(min,m) {
        font-size: 5rem;
        line-height: 5rem;
    }
    margin: 0 0 4.5rem;
    padding: 0;
    font-weight: 400;
    text-transform: uppercase;
}

.statement-text__outro {
    font-size: 2rem;
    line-height: 2.6rem;
    margin: 4.5rem 0 0;
    padding: 0;
    font-weight: 400;
    text-transform: uppercase;
    @include break(min,s) {
        font-size: 2.4rem;
        line-height: 3rem;
    }
}

.testimonial-source {
    font-size: 1.4rem;
    margin: {
        top: 1rem;
    }
    text-align: center;
    display: block;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    width: auto;
    white-space: nowrap;
    color: #fff;
    background-color: rgba(0,0,0,0.4);
    display: inline-block;
    padding: {
        left: 2rem;
        right: 2rem;
    }
}

.content-text {
    padding: {
        left: 3rem;
        right: 3rem;
        top: 3rem;
        bottom: 3rem;
    }
    font-size: 1.4rem;
    line-height: 2.1rem;
    position: relative;
    font-family: $heading__type;
    @include break(min,s) {
        display: inline-block;
        font-size: 1.6rem;
        line-height: 2.4rem;
        width: calc( (70rem / 12) * 10);
        text-align: left;
        box-sizing: border-box;
    }
    @include break(min,m) {
        width: calc( (96rem / 12) * 8);
        top: 50%;
    }
    @include break(min,l) {
        width: calc( (120rem / 12) * 8);
    }
}

.block-heading {
    @include break(min,s) {
        font-size: 3rem;
        line-height: 3.6rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        margin: {
            bottom: 6rem;
        }
    }
    @include break(min,l) {
        font-size: 3.6rem;
    }
}

.content__header {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin: 0 0 4.5rem;
    padding: 0;
    font-weight: 400;
    text-transform: uppercase;
    @include break(min,s) {
        font-size: 4rem;
        line-height: 4rem;
    }
    @include break(min,m) {
        font-size: 5rem;
        line-height: 5rem;
    }
}

.content__intro {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin: 0 0 4.5rem;
    padding: 0;
    font-weight: 400;
    text-transform: uppercase;
    @include break(min,s) {
        font-size: 3rem;
        line-height: 4rem;
    }
}

.page-title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: {
        top: 10rem;
        bottom: 3rem;
    }
    @include break(min,s) {
        font-size: 2.2rem;
        line-height: 3.3rem;
        padding: {
            top: 16rem;
            bottom: 3rem;
        }
    }
}

.inline__title {
    font-size: 1.2rem;
    display: inline-block;
    text-transform: uppercase;
    padding: {
        bottom: 1.5rem;
    }
    margin: {
        bottom: 3rem;
    }
    border: {
        bottom: 1px solid #dededc;
    }
}

.feature-page-title {
    padding: {
        top: 10rem;
        bottom: 1.5rem;
    }
    text-align: center;
    font-size: 3rem;
    text-transform: uppercase;
    line-height: 4rem;
    color: #353535;

    .testimonial-section & {
        color: #fff;
    }
    @include break(min,s) {
        padding: {
            top: 15rem;
            bottom: 3rem;
        }
        font-size: 4rem;
        line-height: 5rem;
    }
    @include break(min,l) {
        padding: {
            top: 20rem;
        }
    }

    & + .feature-page-subtitle {
        padding: {
            top: 0;
        }
    }
}

.feature-page-subtitle {
    padding: {
        top: 10rem;
        bottom: 1.5rem;
    }
    text-align: center;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #777;
    @include break(min,s) {
        font-size: 2rem;
    }
    padding: {
        bottom: 3rem;
    }

    .darken &,
    .moods &,
    .rights & {
        color: #fff;
    }

    & + .film-contact-us
    {
      margin-top: 0;
      margin-bottom: 10rem;
    }
}

.film,
.films {
    position: relative;
    @include break(min,m) {}
}

.film-viewer {
    margin: {
        bottom: 6rem;
    }
    width: 100%;
    @include break(min,s) {
        width: calc((100%/12)*9);
        float: left;
    }

    &--moods,
    &--rights {
        @extend .film-viewer;
        margin: 0 auto;
        @include break(min,s) {
            float: none;
        }
    }
}

.film-details {
    @include clearfix;
    @include break(min,l) {
        width: 100%;
        float: left;
        margin: {
            right: 1.5rem;
        }
    }
}

.film-details__text {
    padding: {
        bottom: 6rem;
    }
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 2.1rem;
    @include break(min,s) {
        width: calc(((100%/12)*9) - 1.5rem);
        margin: {
            right: 1.5rem;
            bottom: 6rem;
        }
        float: left;
        padding: {
            right: 3rem;
        }
        border: {
            bottom: 1px solid #dededc;
        }
    }
}

.film-credits {
    line-height: 2.1rem;
    margin: {
        bottom: 6rem;
    }
    @include break(min,s) {
        width: calc(((100%/12)*3) - 1.5rem);
        margin: {
            left: 1.5rem;
        }
        float: left;
    }
}

.film-details__credits {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 1.2rem;
    line-height: 2.1rem;
}

.film-related {
    @include break(min,l) {
        clear: left;
    }
}

.film-related__testimonial {
    margin: {
        bottom: 6rem;
    }
    @include break(min,m) {
        width: calc(50% - 1.5rem);
        float: left;
        margin: {
            right: 1.5rem;
        }

        &.no-films
        {
          width: 75%;
        }
    }
}

.film-related__testimonial-text {
    margin: 0;
    padding: 0;
    font-size: 1.7rem;
    line-height: 2.6rem;
    font-family: $heading__type;
    font-weight: 500;
    @include break(min,s) {
        padding: {
            right: 3rem;
        }
    }
}

.film-related__testimonial-source {
    margin: {
        top: 1rem;
    };
    font-style: normal;
    display: inline-block;
    font-size: 1.4rem;
    font-style: normal;
}

.film-cta {
    margin: {
        bottom: 6rem;
    }
    border: {
        top: 1px solid #dededc;
    }
    margin: {
        top: 3rem;
    }
    padding: {
        top: 3rem;
    }
}

.film-cta__link {
    color: inherit;
    font-family: $heading__type;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.3rem;
}

.animation-block {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    background-color: #222;
    color: #fff;
    overflow: hidden;
    animation-name: animation-block-hide;
    animation-duration: 0.75s;
    /* or: Xms */
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
    animation-fill-mode: forwards;
    /* or: backwards, both, none */
    animation-delay: 2s;
    /* or: Xms */
}

.secondary-animation-block {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    background-color: #222;
    color: #fff;
    overflow: hidden;
    animation-name: animation-block-hide;
    animation-duration: 0.75s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 3s;
}

.logo-hero {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: #444;
    background-image: url("/assets/images/backgrounds/mountain.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom center;
    color: #fff;
    overflow: hidden;
}

#contact-form {
    @include break(min,m) {
        width: 50%;
    }
    margin: {
        top: 6rem;
    }
}

.standard-input {
    border: 1px solid #dededc;
    width: 100%;
    height: 4rem;
    padding: 0 1.5rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    font-family: inherit;

    &--textarea {
        @extend .standard-input;
        height: 15rem;
        resize: none;
        padding: 1.5rem;
    }
}

.standard-label {
    font-size: 1.3rem;
    font-weight: 600;
}

.feature-label {
    font-size: 1.4rem;
    display: block;
    font-weight: 600;
    margin: {
        top: 4.5rem;
    }
    text-align: center;

    &:first-of-type {
        margin: {
            top: 0;
        }
    }
}

.feature-contact {
    width: 100%;
    border: 0;
    height: 6rem;
    line-height: 6rem;
    font-size: 2rem;
    @include break(min,s) {
        font-size: 3rem;
    }
    display: block;
    font-family: $heading__type;
    text-align: center;
    background-color: transparent;

    a {
        border: {
            bottom: 1px solid transparent;
        }
        transition: border-color 0.3s ease-in-out;
        text-decoration: none;
        color: inherit;

        &:hover {
            border-color: #555;
        }
    }
}

.contact__social-media {
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;
}

.contact__social-media__icon {
    display: inline-block;
}

.contact__social-media__link {
    display: block;
    width: 4rem;
    text-align: center;
    background-color: rgba(255,255,255,0.9);
    text-decoration: none;
    color: inherit;
    border-radius: 50%;
    color: #fff;
    transition: background-color 0.3s ease-in-out;
    margin: {
        left: 1.5rem;
        right: 1.5rem;
        top: 3rem;
    };

    &::before {
        line-height: 4rem !important;
    }

    &.icon-vimeo {
        background-color: #aad450;

        &:hover {
            background-color: darken(#aad450,20);
        }
    }

    &.icon-twitter {
        background-color: #55acee;

        &:hover {
            background-color: darken(#55acee,20);
        }
    }

    &.icon-linkedin {
        background-color: #007bb5;

        &:hover {
            background-color: darken(#007bb5,20);
        }
    }

    span {
        display: none;
    }
}

.contact-information {
    padding: {
        top: 6rem;
        bottom: 6rem;
    }
    margin: {
        top: 3rem;
    }
}
@keyframes animation-block-hide {
    0% {
        height: 100vh;
    }

    100% {
        height: 0;
    }
}

.next-prev-block-container {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 6rem;
    left: 0;
}

.next-block,
.prev-block {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    background-color: rgba(255,255,255,0.9);
    border-radius: 50%;
    position: relative;
    margin: {
        left: 1.5rem;
        right: 1.5rem;
    }
    line-height: 3rem;
    font-size: 1.4rem;
    color: #777;

    &::before {
        line-height: 3rem !important;
        height: 3rem;
        width: 3rem;
        left: 0;
        top: 0;
        position: absolute;
        text-align: center;
    }
}

#wolfgang-logo {
    width: 15rem;
    height: auto;
    position: absolute;
    top: 10vh;
    left: 50vw;
    transform: translate(-50%,0);

    .text {
        fill: #fff;
    }

    .logo {
        fill: #1d1d1c;
    }

    .circle {
        fill: rgba(255,255,255,0.9);
    }
}

#wolfgang-logo--animated {
    width: 30rem;
    height: auto;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%,-50%) scale(1);
    transform-origin: top center;
    animation: logo-up 0.75s ease-in-out forwards;
    animation-delay: 2s;

    .logo {
        fill: rgba(0,0,0,1);
        stroke: #fff;
        stroke-width: 0.02rem;
        stroke-dasharray: 180;
        stroke-dashoffset: 180;
        animation: fast 1s ease-in-out forwards;
    }

    .circle {
        fill: rgba(255,255,255,1);
        stroke: #fff;
        stroke-width: 0.02rem;
        stroke-dasharray: 180;
        stroke-dashoffset: 180;
        animation: fast 1s ease-in-out forwards;
    }

    .text {
        fill: rgba(255,255,255,1);
        // stroke: #fff;
        // stroke-width: 0.02rem;
        // stroke-dasharray: 180;
        // stroke-dashoffset: 180;
        opacity: 0;
        animation: fast 1s ease-in-out forwards;
        animation-delay: 1s;
    }
}
@keyframes logo-up {
    0% {
        top: 50vh;
        left: 50vw;
        transform: translate(-50%,-50%) scale(1);
    }

    45% {
        opacity: 1;
    }

    50% {
        top: 10vh;
        transform: translate(-50%,0) scale(0.5);
        left: 50vw;
        opacity: 0.5;
    }

    100% {
        top: 10vh;
        left: 50vw;
        transform: translate(-50%,0) scale(0.5);
    }
}
@keyframes dash {
    0% {
        stroke-dashoffset: 180;
    }

    70% {
        fill: rgba(255,255,255,0);
    }

    90% {
        stroke-dashoffset: 0;
        stroke: rgba(255,255,255,1);
    }

    98% {
        stroke: rgba(255,255,255,0);
    }

    100% {
        fill: rgba(255,255,255,1);
    }
}
@keyframes dash-w {
    0% {
        stroke-dashoffset: 200;
    }

    70% {
        fill: rgba(255,255,255,0);
    }

    90% {
        stroke-dashoffset: 0;
        stroke: rgba(255,255,255,1);
    }

    98% {
        stroke: rgba(255,255,255,0);
    }

    100% {
        fill: #1d1d1c;
    }
}
@keyframes fast {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.logo-hero__title {
    left: 50vw;
    top: 60vh;
    position: absolute;
    transform: translate(-50%,-50%);
    text-transform: uppercase;
    font-family: $heading__type;
    color: #fff;
    font-size: 2.4rem;
    line-height: 2.4rem;
    text-shadow: 0 0.5rem 1rem rgba(0,0,0,0.4);
    width: 100%;
    text-align: center;
    @include break(min,s) {
        font-size: 5rem;
        line-height: 5rem;
    }
    @include break(min,l) {
        font-size: 4rem;
    }

    .first {
        opacity: 0;
        display: inline;
        animation: fast 0.5s ease-in forwards;
        animation-delay: 3s;

        .no-intro & {
            animation-delay: 0s;
        }

        .moods .no-intro &,
        .rights .no-intro &
        {
          animation-delay: 1s;
        }
    }

    .second {
        opacity: 0;
        display: inline;
        animation: fast 0.5s ease-in forwards;
        animation-delay: 3.75s;

        .no-intro & {
            animation-delay: .75s;
        }

        .moods .no-intro &,
        .rights .no-intro &
        {
          animation-delay: 2s;
        }
    }

    .third {
        opacity: 0;
        display: inline;
        animation: fast 0.5s ease-in forwards;
        animation-delay: 4.5s;

        .no-intro & {
            animation-delay: 1.5s;
        }

        .moods .no-intro &,
        .rights .no-intro &
        {
          animation-delay: 4s;
        }
    }
}

.films-entries {
    list-style-type: none;
    padding: 0;
    margin: 6rem 0 0;
}

.films-entries__entry {
    background-size: cover;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 100%;
    height: calc(90vw - 6rem);
    position: relative;
    border: 1px solid #fff;
    @include break(min,s) {
        width: 35rem;
        height: 35rem;
    }
    @include break(min,m) {
        width: 48rem;
        height: 48rem;
    }
    @include break(min,l) {
        width: 60rem;
        height: 60rem;
    }

    &.small {
        @include break(min,s) {
            width: calc(70rem/3);
            height: calc(70rem/3);
        }
        @include break(min,m) {
            width: calc(96rem/3);
            height: calc(96rem/3);
        }
        @include break(min,l) {
            width: calc(120rem/3);
            height: calc(120rem/3);
        }
    }
    float: left;
}

.films-entries__entry__image {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%,-50%) scale(1);
    transition: all 0.3s ease-in-out;

    .films-entries__entry:hover & {
        transform: translate(-50%,-50%) scale(1.2);
    }
}

.films-entries__entry__link {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    padding: 3rem;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.4);
    @include break(min,s) {
        background-color: rgba(0,0,0,0);
    }
    transition: all 0.3s ease-in-out;

    .films-entries__entry:hover & {
        background-color: rgba(0,0,0,0.6);
    }
    color: #fff;
    font-family: $heading__type;
    text-transform: uppercase;
}

.films-entries__entry__overview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    text-align: center;
}

.films-entries__entry__title {
    font-family: $heading__type;
    transition: all 0.3s ease-in-out;

    .films-entries__entry__title__category {
        display: block;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        font-size: 1.2rem;
        @include break(min,s) {
            transform: scale(0);
        };
    }
    @include break(min,s) {
        opacity: 0;

        .films-entries__entry:hover & {
            opacity: 1;
            transition: all 0.3s ease-in-out;

            & .films-entries__entry__title__category {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.moods-text,
.rights-text {
    color: #fff;
    padding: {
        left: 3rem;
        right: 3rem;
        top: 3rem;
        bottom: 3rem;
    }
    font-size: 1.4rem;
    line-height: 2.1rem;
    position: relative;
    font-family: $heading__type;
    @include break(min,s) {
        width: calc((100%/12)*9);
        margin-top: 6rem;
        margin-left: calc((100%/12)*1.5);
        display: inline-block;
        font-size: 1.6rem;
        line-height: 2.4rem;
        padding: {
            left: 0;
        }
    }
}

.moods-link,
.rights-link {
    color: inherit;
    font-weight: 600;
}

.moods-heading,
.rights-heading {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 600;
    margin: {
        bottom: 3rem;
    }
    text-transform: uppercase;
}


.rights-heading
{
  margin:
  {
    top: 5rem;
  }
}

.rights-heading:first-of-type
{
  margin:
  {
    top: 0rem;
  }
}

.testimonial-block
{
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.list
  {
    padding:
    {
      top: 3rem;
    }
  }
}

.testimonial
{
  color: #fff;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  clear: left;
  display: inline-block;

  &:only-of-type
  {
    padding:
    {
      top: 12rem;
    }
  }

  & + .testimonial
  {
    margin:
    {
      top: 6rem;
    }
  }
  @include break(min,s)
  {
    padding:
    {
      left: 20vw;
    }
  }
  @include break(min,m)
  {
    width: calc((100%/12)*10);
    margin:
    {
      left: calc(100%/12);
    }
    padding:
    {
      left: 13vw;
    }
  }

}


.testimonial-logo__container
{
  background-color: #fff;
  width: 24vw;
  height: 24vw;
  position: relative;
  left: 50%;
  transform: translate(-50%,0);
  margin: 0;

  @include break(min,s)
  {
    width: 14vw;
    height: 14vw;
    position: absolute;
    transform: none;
    left: 0;
    top: 1vw;

    .testimonial:only-of-type &
    {
      top: 12rem;
    }
  }

  @include break(min,m)
  {
    width: 6vw;
    height: 6vw;
    top: 0.5vw;
  }
}

.testimonial-logo
{
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.testimonial__text
{
  margin: {
    top: 6rem;
    left: 0;
    right: 0;
    bottom: 3rem;
  };
  padding:
  {
    left: 3rem;
    right: 3rem;
    top: 0;
    bottom: 0;
  };

  @include break(min,s)
  {
    padding:
    {
      left: 0;
    }
    margin:
    {
      top: 4.5rem;
    }
  }

  @include break(min,m)
  {
    font-size: 2rem;
    line-height: 3rem;
    margin:
    {
      top: 0;
    }
    .list &
    {
      font-size: 1.8rem;
      line-height: 2.7rem;
    }
  }
}

.testimonial__image
{
  max-width: 18vw;
  max-height: 18vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  left: 50%;
  transform: translate(-50%,0);

  @include break(min,s)
  {
    left: 0;
    transform: none;
    width: 5vw;
    height: 5vw;
    float: left;
  }
  @include break(min,m)
  {
    width: 3vw;
    height: 3vw;
  }
}


.testimonial__source
{
  font-size: 1.4rem;
  text-align: center;
  display: block;
  font-weight: 500;
  font-style: normal;
  margin:
  {
    top: 1.5rem;
  }

  @include break(min,s)
  {
    display: inline-block;
    float: left;
    line-height: 5vw;
    margin:
    {
      top: 0;
      left: 1.5rem;
    }
  }

  @include break(min,m)
  {
    line-height: 3vw;
  }
}

.testimonial-related
{
  display: inline-block;
  background-color: #fff;
  color: #555;
  text-decoration: none;
  text-transform: uppercase;
  padding:
  {
    left: 1.5rem;
    right: 1.5rem;
  }
  line-height: 4rem;
  position: relative;
  left: 50%;
  transform: translate(-50%,0);
  font-size: 1.2rem;
  font-weight: 600;
  margin:
  {
    top: 3rem;
  }
  transition: all 0.3s ease-in-out;
  &:hover
  {
    background-color: #ddd;
  }

  .content-text &
  {
    background-color: #eee;
    &:hover
    {
      background-color: #ccc;
    }
  }
  @include break(min,s)
  {
    float: right;
    left: 0;
    transform: none;
    margin:
    {
      top: 4.5rem;
      right: 3rem;
    }

    .content-text &
    {

      margin:
      {
        right: 0;
      }
    }


  }
  @include break(min,m)
  {
    margin: 0;
    margin:
    {
      right: 3rem;
    }
    .content-text &
    {

      margin:
      {
        right: 0;
      }
    }
  }
}

.testimonial-related__container
{
  float: right;

  .content-text &
  {
    margin:
    {
      top: -3rem;
    }
  }
}



.cls-1 {
    fill: #fff;
    .follow &
    {
      fill: #1d1c1b;
    }

}
.cls-2,
.cls-3 {
  fill: #fff;
  stroke: #fff;
  .follow &
  {
    fill: #1d1c1b;
    stroke: #1d1c1b;
  }
}
.cls-3 {
    fill: none;
    stroke-miterlimit: 10;
    stroke: #fff;
    .follow &
    {
      stroke: #1d1c1b;
    }
}

.st0
{
  fill: #fff;

}

#wm
{
  width: 26rem;
  height: auto;
  position: absolute;
  top: 30vh;
  left: 50%;
  transform: translate(-50%,0);
  animation-name: fast;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

.film-contact-us
{
  text-align: center;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  font-family: $heading__type;
  text-decoration: none;
  padding: 1rem 2rem;
  background-color: #eee;
  color: #353535;
  font-size: 1.4rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  left: 50%;
  transform: translate(-50%,0%);
  margin:
  {
    top: 8rem;
  }
  &:hover
  {
    background-color: #ccc;
  }
}


.about-background,
 .moods-background,
 .moods-splash,
 .rights-background {
   background-attachment: fixed;
 }

 .about-background.contain,
 .moods-background.contain,
 .moods-splash.contain,
 .rights-splash.contain
 .rights-background.contain {
   background-size: contain;
 }

 .about-background.cover,
 .moods-background.cover,
 .moods-splash.cover,
  .rights-splash.cover,
 .rights-background.cover {
   background-size: cover;
 }


 .about-background, .moods-background, .moods-splash, .rights-splash, .rights-background {
    background-image: url("/assets/images/backgrounds/cropped-trees.jpg");
    background-size: auto 400px;
    background-position: bottom right;
    background-repeat: no-repeat;
    color: #fff;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
}

.moods-background {
    background-position: center;
    background-image: url("/assets/images/backgrounds/lava.jpg");
    background-size: auto 100vh;
}

.rights-background {
    background-position: center;
    background-image: url("/assets/images/backgrounds/festival.jpg");
    background-size: auto 100vh;
}

.moods-splash {
    background-image: url("/assets/images/backgrounds/storm.jpg");
    background-size: auto 100vh;
    background-position: center;
}

.moods-splash {
    background-image: url("/assets/images/backgrounds/baseball.jpg");
    background-size: auto 100vh;
    background-position: center;
}
