html
{
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;

  position: relative;
}

body
{
  font-family: $body__type;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  color: #555;
}

h1,h2,h3,h4,h5,h6
{
  font-family: $heading__type;
  font-size: inherit;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.container
{
	@include clearfix;
  //position: relative;
	width: calc(100vw - 6rem);
  position: relative;
	margin:
	{
		top: 0;
		right: auto;
		bottom: 0;
		left: auto;
	}

	@include break(min,s)
	{
		width: 70rem;
	}

	@include break(min,m)
	{
		width: 96rem;
	}

	@include break(min,l)
	{
		width: 120rem;
	}
}


p
{
  margin: 0;
  padding: 0;

& + p
{
  margin:
  {
    top: 2.4rem;
  }
}
}
