html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  position: relative;
}

body {
  font-family: "Hind", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  color: #555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-size: inherit;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.container {
  width: calc(100vw - 6rem);
  position: relative;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

p {
  margin: 0;
  padding: 0;
}

p + p {
  margin-top: 2.4rem;
}

.main-header {
  width: 100%;
  z-index: 10;
}

.main-header:after {
  content: "";
  display: table;
  clear: both;
}

.main-nav {
  width: 60%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 3rem;
  box-sizing: border-box;
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  transition: transform 0.3s ease-in-out;
}

.main-nav.reveal {
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.main-nav__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main-nav__list-item {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 4rem;
}

.main-nav__list-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.main-nav__list-link--button {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.main-nav__logo {
  width: 100%;
  margin-bottom: 3rem;
}

.main-nav__logo__type {
  stroke: #000;
  fill: #1D1C1B;
}

.darken .main-nav__logo__type,
.moods .main-nav__logo__type,
.righs .main-nav__logo__type {
  stroke: #fff;
  fill: #fff;
}

.main-nav__menu-toggle {
  font-size: 1.8rem;
  padding: 0;
  box-sizing: border-box;
  line-height: 4.3rem;
  position: absolute;
  left: -6rem;
  top: 3rem;
  background-color: rgba(255, 255, 255, 0.95);
  border: 0;
  -webkit-appearance: none;
  transition: all 0.3s ease-in-out;
  width: 4rem;
  height: 4rem;
}

.reveal .main-nav__menu-toggle {
  left: -4rem;
}

.main-nav__menu-toggle:focus {
  outline: none;
}

.main-nav__menu-toggle span {
  display: none;
}

.main-footer {
  position: relative;
  z-index: 10;
  background: linear-gradient(to top, #282828 20%, rgba(40, 40, 40, 0) 100%);
  color: #eee;
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  height: 35rem;
  box-sizing: border-box;
  background-position: top center;
  background-size: cover;
}

.main-footer__home-image--roundel,
.main-footer__home-image--type {
  max-width: 9rem;
  position: relative;
  z-index: 2;
  margin-bottom: 3rem;
}

.main-footer__home-image--type {
  max-width: 12rem;
}

.contact-details {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
}

.contact-details__address {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: inline-block;
}

.contact-details__telephone-email {
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.contact-details__telephone-email a {
  color: inherit;
}

#content,
.content-block,
.statement-block,
.testimonial-block {
  position: relative;
}

#content:after,
.content-block:after,
.statement-block:after,
.testimonial-block:after {
  content: "";
  display: table;
  clear: both;
}

#content,
.content-block,
.statement-block {
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.statement-block {
  min-height: 92vh;
  box-sizing: border-box;
}

.content-block,
.film-block {
  padding-top: 0;
  padding-bottom: 20rem;
  box-sizing: border-box;
}

.film-block {
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.content-block:last-of-type {
  padding-bottom: 60rem;
  margin-bottom: -34rem;
  box-sizing: content-box;
}

.testimonial-block:last-of-type {
  padding-bottom: 40rem;
  margin-bottom: -34rem;
  box-sizing: content-box;
}

.testimonial-block {
  text-align: center;
  height: auto;
  background-image: url("/assets/images/backgrounds/sunset.jpg");
  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.testimonial-section .testimonial-block {
  background-image: none;
  box-shadow: none;
}

.moods .content,
.rights .content,
.moods
.statement,
.rights
.statement {
  color: #fff;
}

.film-related__films {
  list-style-type: none;
  clear: left;
  margin: 0;
  padding: 0;
}

.film-related__films__film {
  margin-bottom: 3rem;
  width: 100%;
  height: 16rem;
  position: relative;
  display: block;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.film-related__films__film__link {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
}

.film-related__films__film__title {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.statement {
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 10rem;
  color: #555;
}

.content {
  width: 100vw;
  left: 0;
  position: relative;
  color: #353535;
}

.statement-text {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: left;
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: "Montserrat", sans-serif;
}

.statement-text__heading {
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0 0 4.5rem;
  padding: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.statement-text__outro {
  font-size: 2rem;
  line-height: 2.6rem;
  margin: 4.5rem 0 0;
  padding: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.testimonial-source {
  font-size: 1.4rem;
  margin-top: 1rem;
  text-align: center;
  display: block;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  width: auto;
  white-space: nowrap;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  padding-left: 2rem;
  padding-right: 2rem;
}

.content-text {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.content__header {
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0 0 4.5rem;
  padding: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.content__intro {
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0 0 4.5rem;
  padding: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.page-title {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 10rem;
  padding-bottom: 3rem;
}

.inline__title {
  font-size: 1.2rem;
  display: inline-block;
  text-transform: uppercase;
  padding-bottom: 1.5rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #dededc;
}

.feature-page-title {
  padding-top: 10rem;
  padding-bottom: 1.5rem;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  line-height: 4rem;
  color: #353535;
}

.testimonial-section .feature-page-title {
  color: #fff;
}

.feature-page-title + .feature-page-subtitle {
  padding-top: 0;
}

.feature-page-subtitle {
  padding-top: 10rem;
  padding-bottom: 1.5rem;
  text-align: center;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #777;
  padding-bottom: 3rem;
}

.darken .feature-page-subtitle,
.moods .feature-page-subtitle,
.rights .feature-page-subtitle {
  color: #fff;
}

.feature-page-subtitle + .film-contact-us {
  margin-top: 0;
  margin-bottom: 10rem;
}

.film,
.films {
  position: relative;
}

.film-viewer,
.film-viewer--moods,
.film-viewer--rights {
  margin-bottom: 6rem;
  width: 100%;
}

.film-viewer--moods,
.film-viewer--rights {
  margin: 0 auto;
}

.film-details:after {
  content: "";
  display: table;
  clear: both;
}

.film-details__text {
  padding-bottom: 6rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.film-credits {
  line-height: 2.1rem;
  margin-bottom: 6rem;
}

.film-details__credits {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 1.2rem;
  line-height: 2.1rem;
}

.film-related__testimonial {
  margin-bottom: 6rem;
}

.film-related__testimonial-text {
  margin: 0;
  padding: 0;
  font-size: 1.7rem;
  line-height: 2.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.film-related__testimonial-source {
  margin-top: 1rem;
  font-style: normal;
  display: inline-block;
  font-size: 1.4rem;
  font-style: normal;
}

.film-cta {
  margin-bottom: 6rem;
  border-top: 1px solid #dededc;
  margin-top: 3rem;
  padding-top: 3rem;
}

.film-cta__link {
  color: inherit;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.3rem;
}

.animation-block {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: #222;
  color: #fff;
  overflow: hidden;
  animation-name: animation-block-hide;
  animation-duration: 0.75s;
  /* or: Xms */
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: forwards;
  /* or: backwards, both, none */
  animation-delay: 2s;
  /* or: Xms */
}

.secondary-animation-block {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: #222;
  color: #fff;
  overflow: hidden;
  animation-name: animation-block-hide;
  animation-duration: 0.75s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}

.logo-hero {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #444;
  background-image: url("/assets/images/backgrounds/mountain.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom center;
  color: #fff;
  overflow: hidden;
}

#contact-form {
  margin-top: 6rem;
}

.standard-input,
.standard-input--textarea {
  border: 1px solid #dededc;
  width: 100%;
  height: 4rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-family: inherit;
}

.standard-input--textarea {
  height: 15rem;
  resize: none;
  padding: 1.5rem;
}

.standard-label {
  font-size: 1.3rem;
  font-weight: 600;
}

.feature-label {
  font-size: 1.4rem;
  display: block;
  font-weight: 600;
  margin-top: 4.5rem;
  text-align: center;
}

.feature-label:first-of-type {
  margin-top: 0;
}

.feature-contact {
  width: 100%;
  border: 0;
  height: 6rem;
  line-height: 6rem;
  font-size: 2rem;
  display: block;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  background-color: transparent;
}

.feature-contact a {
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s ease-in-out;
  text-decoration: none;
  color: inherit;
}

.feature-contact a:hover {
  border-color: #555;
}

.contact__social-media {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.contact__social-media__icon {
  display: inline-block;
}

.contact__social-media__link {
  display: block;
  width: 4rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  color: inherit;
  border-radius: 50%;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
}

.contact__social-media__link::before {
  line-height: 4rem !important;
}

.contact__social-media__link.icon-vimeo {
  background-color: #aad450;
}

.contact__social-media__link.icon-vimeo:hover {
  background-color: #749925;
}

.contact__social-media__link.icon-twitter {
  background-color: #55acee;
}

.contact__social-media__link.icon-twitter:hover {
  background-color: #147bc9;
}

.contact__social-media__link.icon-linkedin {
  background-color: #007bb5;
}

.contact__social-media__link.icon-linkedin:hover {
  background-color: #00364f;
}

.contact__social-media__link span {
  display: none;
}

.contact-information {
  padding-top: 6rem;
  padding-bottom: 6rem;
  margin-top: 3rem;
}

@keyframes animation-block-hide {
  0% {
    height: 100vh;
  }

  100% {
    height: 0;
  }
}

.next-prev-block-container {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 6rem;
  left: 0;
}

.next-block,
.prev-block {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  position: relative;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  line-height: 3rem;
  font-size: 1.4rem;
  color: #777;
}

.next-block::before,
.prev-block::before {
  line-height: 3rem !important;
  height: 3rem;
  width: 3rem;
  left: 0;
  top: 0;
  position: absolute;
  text-align: center;
}

#wolfgang-logo {
  width: 15rem;
  height: auto;
  position: absolute;
  top: 10vh;
  left: 50vw;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

#wolfgang-logo .text {
  fill: #fff;
}

#wolfgang-logo .logo {
  fill: #1d1d1c;
}

#wolfgang-logo .circle {
  fill: rgba(255, 255, 255, 0.9);
}

#wolfgang-logo--animated {
  width: 30rem;
  height: auto;
  position: absolute;
  top: 50vh;
  left: 50vw;
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -ms-transform-origin: top center;
  transform-origin: top center;
  animation: logo-up 0.75s ease-in-out forwards;
  animation-delay: 2s;
}

#wolfgang-logo--animated .logo {
  fill: black;
  stroke: #fff;
  stroke-width: 0.02rem;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  animation: fast 1s ease-in-out forwards;
}

#wolfgang-logo--animated .circle {
  fill: white;
  stroke: #fff;
  stroke-width: 0.02rem;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  animation: fast 1s ease-in-out forwards;
}

#wolfgang-logo--animated .text {
  fill: white;
  opacity: 0;
  animation: fast 1s ease-in-out forwards;
  animation-delay: 1s;
}

@keyframes logo-up {
  0% {
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%) scale(1);
  }

  45% {
    opacity: 1;
  }

  50% {
    top: 10vh;
    transform: translate(-50%, 0) scale(0.5);
    left: 50vw;
    opacity: 0.5;
  }

  100% {
    top: 10vh;
    left: 50vw;
    transform: translate(-50%, 0) scale(0.5);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 180;
  }

  70% {
    fill: rgba(255, 255, 255, 0);
  }

  90% {
    stroke-dashoffset: 0;
    stroke: white;
  }

  98% {
    stroke: rgba(255, 255, 255, 0);
  }

  100% {
    fill: white;
  }
}

@keyframes dash-w {
  0% {
    stroke-dashoffset: 200;
  }

  70% {
    fill: rgba(255, 255, 255, 0);
  }

  90% {
    stroke-dashoffset: 0;
    stroke: white;
  }

  98% {
    stroke: rgba(255, 255, 255, 0);
  }

  100% {
    fill: #1d1d1c;
  }
}

@keyframes fast {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.logo-hero__title {
  left: 50vw;
  top: 60vh;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 2.4rem;
  line-height: 2.4rem;
  text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
  width: 100%;
  text-align: center;
}

.logo-hero__title .first {
  opacity: 0;
  display: inline;
  animation: fast 0.5s ease-in forwards;
  animation-delay: 3s;
}

.no-intro .logo-hero__title .first {
  animation-delay: 0s;
}

.moods .no-intro .logo-hero__title .first,
.rights .no-intro .logo-hero__title .first {
  animation-delay: 1s;
}

.logo-hero__title .second {
  opacity: 0;
  display: inline;
  animation: fast 0.5s ease-in forwards;
  animation-delay: 3.75s;
}

.no-intro .logo-hero__title .second {
  animation-delay: .75s;
}

.moods .no-intro .logo-hero__title .second,
.rights .no-intro .logo-hero__title .second {
  animation-delay: 2s;
}

.logo-hero__title .third {
  opacity: 0;
  display: inline;
  animation: fast 0.5s ease-in forwards;
  animation-delay: 4.5s;
}

.no-intro .logo-hero__title .third {
  animation-delay: 1.5s;
}

.moods .no-intro .logo-hero__title .third,
.rights .no-intro .logo-hero__title .third {
  animation-delay: 4s;
}

.films-entries {
  list-style-type: none;
  padding: 0;
  margin: 6rem 0 0;
}

.films-entries__entry {
  background-size: cover;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  width: 100%;
  height: calc(90vw - 6rem);
  position: relative;
  border: 1px solid #fff;
  float: left;
}

.films-entries__entry__image {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.3s ease-in-out;
}

.films-entries__entry:hover .films-entries__entry__image {
  -ms-transform: translate(-50%, -50%) scale(1.2);
  transform: translate(-50%, -50%) scale(1.2);
}

.films-entries__entry__link {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  padding: 3rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.films-entries__entry:hover .films-entries__entry__link {
  background-color: rgba(0, 0, 0, 0.6);
}

.films-entries__entry__overview {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.films-entries__entry__title {
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease-in-out;
}

.films-entries__entry__title .films-entries__entry__title__category {
  display: block;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  font-size: 1.2rem;
}

.moods-text,
.rights-text {
  color: #fff;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.moods-link,
.rights-link {
  color: inherit;
  font-weight: 600;
}

.moods-heading,
.rights-heading {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 600;
  margin-bottom: 3rem;
  text-transform: uppercase;
}

.rights-heading {
  margin-top: 5rem;
}

.rights-heading:first-of-type {
  margin-top: 0rem;
}

.testimonial-block {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.testimonial-block.list {
  padding-top: 3rem;
}

.testimonial {
  color: #fff;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  clear: left;
  display: inline-block;
}

.testimonial:only-of-type {
  padding-top: 12rem;
}

.testimonial + .testimonial {
  margin-top: 6rem;
}

.testimonial-logo__container {
  background-color: #fff;
  width: 24vw;
  height: 24vw;
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  margin: 0;
}

.testimonial-logo {
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.testimonial__text {
  margin-top: 6rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0;
  padding-bottom: 0;
}

.testimonial__image {
  max-width: 18vw;
  max-height: 18vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.testimonial__source {
  font-size: 1.4rem;
  text-align: center;
  display: block;
  font-weight: 500;
  font-style: normal;
  margin-top: 1.5rem;
}

.testimonial-related {
  display: inline-block;
  background-color: #fff;
  color: #555;
  text-decoration: none;
  text-transform: uppercase;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  line-height: 4rem;
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 3rem;
  transition: all 0.3s ease-in-out;
}

.testimonial-related:hover {
  background-color: #ddd;
}

.content-text .testimonial-related {
  background-color: #eee;
}

.content-text .testimonial-related:hover {
  background-color: #ccc;
}

.testimonial-related__container {
  float: right;
}

.content-text .testimonial-related__container {
  margin-top: -3rem;
}

.cls-1 {
  fill: #fff;
}

.follow .cls-1 {
  fill: #1d1c1b;
}

.cls-2,
.cls-3 {
  fill: #fff;
  stroke: #fff;
}

.follow .cls-2,
.follow
  .cls-3 {
  fill: #1d1c1b;
  stroke: #1d1c1b;
}

.cls-3 {
  fill: none;
  stroke-miterlimit: 10;
  stroke: #fff;
}

.follow .cls-3 {
  stroke: #1d1c1b;
}

.st0 {
  fill: #fff;
}

#wm {
  width: 26rem;
  height: auto;
  position: absolute;
  top: 30vh;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  animation-name: fast;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

.film-contact-us {
  text-align: center;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  padding: 1rem 2rem;
  background-color: #eee;
  color: #353535;
  font-size: 1.4rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  margin-top: 8rem;
}

.film-contact-us:hover {
  background-color: #ccc;
}

.about-background,
.moods-background,
.moods-splash,
.rights-background {
  background-attachment: fixed;
}

.about-background.contain,
.moods-background.contain,
.moods-splash.contain,
.rights-splash.contain .rights-background.contain {
  background-size: contain;
}

.about-background.cover,
.moods-background.cover,
.moods-splash.cover,
.rights-splash.cover,
.rights-background.cover {
  background-size: cover;
}

.about-background,
.moods-background,
.moods-splash,
.rights-splash,
.rights-background {
  background-image: url("/assets/images/backgrounds/cropped-trees.jpg");
  background-size: auto 400px;
  background-position: bottom right;
  background-repeat: no-repeat;
  color: #fff;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

.moods-background {
  background-position: center;
  background-image: url("/assets/images/backgrounds/lava.jpg");
  background-size: auto 100vh;
}

.rights-background {
  background-position: center;
  background-image: url("/assets/images/backgrounds/festival.jpg");
  background-size: auto 100vh;
}

.moods-splash {
  background-image: url("/assets/images/backgrounds/storm.jpg");
  background-size: auto 100vh;
  background-position: center;
}

.moods-splash {
  background-image: url("/assets/images/backgrounds/baseball.jpg");
  background-size: auto 100vh;
  background-position: center;
}

@font-face {
  font-family: 'Montserrat';
  src: url("/assets/webfonts/montserrat/Montserrat-Light.eot");
  src: url("/assets/webfonts/montserrat/Montserrat-Light.woff2") format("woff2"), url("/assets/webfonts/montserrat/Montserrat-Light.woff") format("woff"), url("/assets/webfonts/montserrat/Montserrat-Light.ttf") format("truetype"), url("/assets/webfonts/montserrat/Montserrat-Light.svg#Montserrat") format("svg"), url("/assets/webfonts/montserrat/Montserrat-Light.eot?#iefix") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("/assets/webfonts/montserrat/Montserrat-Regular.eot");
  src: url("/assets/webfonts/montserrat/Montserrat-Regular.woff2") format("woff2"), url("/assets/webfonts/montserrat/Montserrat-Regular.woff") format("woff"), url("/assets/webfonts/montserrat/Montserrat-Regular.ttf") format("truetype"), url("/assets/webfonts/montserrat/Montserrat-Regular.svg#Montserrat") format("svg"), url("/assets/webfonts/montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("/assets/webfonts/montserrat/Montserrat-Medium.eot");
  src: url("/assets/webfonts/montserrat/Montserrat-Medium.woff2") format("woff2"), url("/assets/webfonts/montserrat/Montserrat-Medium.woff") format("woff"), url("/assets/webfonts/montserrat/Montserrat-Medium.ttf") format("truetype"), url("/assets/webfonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium") format("svg"), url("/assets/webfonts/montserrat/Montserrat-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("/assets/webfonts/montserrat/Montserrat-Bold.eot");
  src: url("/assets/webfonts/montserrat/Montserrat-Bold.woff2") format("woff2"), url("/assets/webfonts/montserrat/Montserrat-Bold.woff") format("woff"), url("/assets/webfonts/montserrat/Montserrat-Bold.ttf") format("truetype"), url("/assets/webfonts/montserrat/Montserrat-Bold.svg#Montserrat") format("svg"), url("/assets/webfonts/montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("/assets/webfonts/montserrat/Montserrat-Black.eot");
  src: url("/assets/webfonts/montserrat/Montserrat-Black.woff2") format("woff2"), url("/assets/webfonts/montserrat/Montserrat-Black.woff") format("woff"), url("/assets/webfonts/montserrat/Montserrat-Black.ttf") format("truetype"), url("/assets/webfonts/montserrat/Montserrat-Black.svg#Montserrat") format("svg"), url("/assets/webfonts/montserrat/Montserrat-Black.eot?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 70rem;
  }

  .main-header {
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease-in-out;
  }

  .main-header.reset-top {
    top: auto;
  }

  .main-header.follow {
    background-color: rgba(255, 255, 255, 0.85);
    position: fixed;
    top: 0;
    border: 0;
  }

  .main-header.follow:hover {
    background-color: white;
  }

  .main-nav {
    background-color: transparent;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    position: static;
    width: 70rem;
    margin: 0 auto;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    padding-left: 0;
    padding-right: 0;
    transition: padding 0.3s ease-in-out;
  }

  .main-nav:after {
    content: "";
    display: table;
    clear: both;
  }

  .main-nav__list {
    float: right;
    position: relative;
    top: 1rem;
  }

  .follow .main-nav__list {
    top: 0.5rem;
  }

  .main-nav__list-item {
    float: left;
    margin-left: 3rem;
  }

  .main-nav__list-link {
    border-bottom: 1px solid transparent;
    padding-bottom: 1rem;
    transition: border-color 0.3s ease-in-out;
  }

  .darken .main-nav__list-link,
  .moods .main-nav__list-link,
  .rights .main-nav__list-link {
    color: #fff;
  }

  .darken .follow .main-nav__list-link,
  .moods .follow .main-nav__list-link,
  .rights .follow .main-nav__list-link {
    color: #555;
  }

  .main-nav__list-link:hover {
    padding-bottom: 1rem;
    border-color: #EC7C3C;
  }

  .main-nav__list-link.selected {
    border-bottom: 2px solid #555;
  }

  .main-nav__list-link--button {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    padding: 1.5rem 2.5rem;
    transition: all 0.3s ease-in-out;
  }

  .main-nav__list-link--button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .main-nav__logo {
    width: auto;
    float: left;
    max-height: 5rem;
    margin-bottom: 0;
  }

  .darken .follow .main-nav__logo__type,
  .moods .follow .main-nav__logo__type,
  .rights .follow .main-nav__logo__type {
    stroke: #000;
    fill: #1D1C1B;
  }

  .main-nav__menu-toggle {
    display: none;
  }

  .main-footer__home-link {
    display: inline-block;
    width: 12rem;
    position: relative;
  }

  .testimonial-block {
    text-align: center;
  }

  .film-related__films__film {
    width: calc(50% - 1.5rem);
    float: left;
  }

  .film-related__films__film:nth-of-type(even) {
    margin-left: 3rem;
  }

  .no-testimonials .film-related__films__film {
    width: calc(33.33% - 1.5rem);
  }

  .no-testimonials .film-related__films__film:nth-of-type(even) {
    margin-left: 0;
  }

  .no-testimonials .film-related__films__film:nth-of-type(3n+2) {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .statement {
    position: absolute;
    left: 0;
    top: 52vh;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin-top: 0;
    margin-bottom: 0;
  }

  .statement-text {
    display: inline-block;
    width: calc( (70rem / 12) * 10);
    text-align: left;
    box-sizing: border-box;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .statement-text__heading {
    font-size: 4rem;
    line-height: 4rem;
  }

  .statement-text__outro {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .content-text {
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.4rem;
    width: calc( (70rem / 12) * 10);
    text-align: left;
    box-sizing: border-box;
  }

  .block-heading {
    font-size: 3rem;
    line-height: 3.6rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-bottom: 6rem;
  }

  .content__header {
    font-size: 4rem;
    line-height: 4rem;
  }

  .content__intro {
    font-size: 3rem;
    line-height: 4rem;
  }

  .page-title {
    font-size: 2.2rem;
    line-height: 3.3rem;
    padding-top: 16rem;
    padding-bottom: 3rem;
  }

  .feature-page-title {
    padding-top: 15rem;
    padding-bottom: 3rem;
    font-size: 4rem;
    line-height: 5rem;
  }

  .feature-page-subtitle {
    font-size: 2rem;
  }

  .film-viewer,
  .film-viewer--moods,
  .film-viewer--rights {
    width: calc((100%/12)*9);
    float: left;
  }

  .film-viewer--moods,
  .film-viewer--rights {
    float: none;
  }

  .film-details__text {
    width: calc(((100%/12)*9) - 1.5rem);
    margin-right: 1.5rem;
    margin-bottom: 6rem;
    float: left;
    padding-right: 3rem;
    border-bottom: 1px solid #dededc;
  }

  .film-credits {
    width: calc(((100%/12)*3) - 1.5rem);
    margin-left: 1.5rem;
    float: left;
  }

  .film-related__testimonial-text {
    padding-right: 3rem;
  }

  .feature-contact {
    font-size: 3rem;
  }

  .logo-hero__title {
    font-size: 5rem;
    line-height: 5rem;
  }

  .films-entries__entry {
    width: 35rem;
    height: 35rem;
  }

  .films-entries__entry.small {
    width: calc(70rem/3);
    height: calc(70rem/3);
  }

  .films-entries__entry__link {
    background-color: transparent;
  }

  .films-entries__entry__title .films-entries__entry__title__category {
    -ms-transform: scale(0);
    transform: scale(0);
  }

  .films-entries__entry__title {
    opacity: 0;
  }

  .films-entries__entry:hover .films-entries__entry__title {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .films-entries__entry:hover .films-entries__entry__title .films-entries__entry__title__category {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  .moods-text,
  .rights-text {
    width: calc((100%/12)*9);
    margin-top: 6rem;
    margin-left: calc((100%/12)*1.5);
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-left: 0;
  }

  .testimonial {
    padding-left: 20vw;
  }

  .testimonial-logo__container {
    width: 14vw;
    height: 14vw;
    position: absolute;
    -ms-transform: none;
    transform: none;
    left: 0;
    top: 1vw;
  }

  .testimonial:only-of-type .testimonial-logo__container {
    top: 12rem;
  }

  .testimonial__text {
    padding-left: 0;
    margin-top: 4.5rem;
  }

  .testimonial__image {
    left: 0;
    -ms-transform: none;
    transform: none;
    width: 5vw;
    height: 5vw;
    float: left;
  }

  .testimonial__source {
    display: inline-block;
    float: left;
    line-height: 5vw;
    margin-top: 0;
    margin-left: 1.5rem;
  }

  .testimonial-related {
    float: right;
    left: 0;
    -ms-transform: none;
    transform: none;
    margin-top: 4.5rem;
    margin-right: 3rem;
  }

  .content-text .testimonial-related {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (min-width: 1024px) {
  .no-testimonials .film-related__films__film {
    width: calc(25% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .no-testimonials .film-related__films__film:nth-of-type(even) {
    margin-left: 1.5rem;
  }

  .no-testimonials .film-related__films__film:nth-of-type(3n+2) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .no-testimonials .film-related__films__film:first-of-type {
    margin-left: 0;
  }

  .no-testimonials .film-related__films__film:last-of-type {
    margin-right: 0;
  }

  .no-testimonials .film-related__films__film:nth-of-type(1n+4) {
    margin-right: 0;
  }

  .no-testimonials .film-related__films__film:nth-of-type(1n+5) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    width: 96rem;
  }

  .main-nav {
    width: 96rem;
  }

  .main-nav.follow {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .contact-details__address {
    display: block;
    width: auto;
    background-color: transparent;
  }

  .contact-details__telephone-email {
    display: block;
    background-color: transparent;
  }

  .film-related__films {
    width: 50%;
    float: left;
    clear: none;
  }

  .film-related__films.no-testimonials {
    width: 100%;
  }

  .statement-text {
    width: calc( (96rem / 12) * 8);
    top: 50%;
  }

  .statement-text__heading {
    font-size: 5rem;
    line-height: 5rem;
  }

  .content-text {
    width: calc( (96rem / 12) * 8);
    top: 50%;
  }

  .content__header {
    font-size: 5rem;
    line-height: 5rem;
  }

  .film-related__testimonial {
    width: calc(50% - 1.5rem);
    float: left;
    margin-right: 1.5rem;
  }

  .film-related__testimonial.no-films {
    width: 75%;
  }

  #contact-form {
    width: 50%;
  }

  .films-entries__entry {
    width: 48rem;
    height: 48rem;
  }

  .films-entries__entry.small {
    width: calc(96rem/3);
    height: calc(96rem/3);
  }

  .testimonial {
    width: calc((100%/12)*10);
    margin-left: calc(100%/12);
    padding-left: 13vw;
  }

  .testimonial-logo__container {
    width: 6vw;
    height: 6vw;
    top: 0.5vw;
  }

  .testimonial__text {
    font-size: 2rem;
    line-height: 3rem;
    margin-top: 0;
  }

  .list .testimonial__text {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  .testimonial__image {
    width: 3vw;
    height: 3vw;
  }

  .testimonial__source {
    line-height: 3vw;
  }

  .testimonial-related {
    margin: 0;
    margin-right: 3rem;
  }

  .content-text .testimonial-related {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    width: 120rem;
  }

  .main-nav {
    width: 120rem;
  }

  .statement-text {
    width: calc( (120rem / 12) * 8);
  }

  .content-text {
    width: calc( (120rem / 12) * 8);
  }

  .block-heading {
    font-size: 3.6rem;
  }

  .feature-page-title {
    padding-top: 20rem;
  }

  .film-details {
    width: 100%;
    float: left;
    margin-right: 1.5rem;
  }

  .film-related {
    clear: left;
  }

  .logo-hero__title {
    font-size: 4rem;
  }

  .films-entries__entry {
    width: 60rem;
    height: 60rem;
  }

  .films-entries__entry.small {
    width: calc(120rem/3);
    height: calc(120rem/3);
  }
}