@font-face {
  font-family: 'Montserrat';
  src: url('/assets/webfonts/montserrat/Montserrat-Light.eot');
  src: url('/assets/webfonts/montserrat/Montserrat-Light.woff2') format('woff2'),
       url('/assets/webfonts/montserrat/Montserrat-Light.woff') format('woff'),
       url('/assets/webfonts/montserrat/Montserrat-Light.ttf') format('truetype'),
       url('/assets/webfonts/montserrat/Montserrat-Light.svg#Montserrat') format('svg'),
       url('/assets/webfonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/webfonts/montserrat/Montserrat-Regular.eot');
  src: url('/assets/webfonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
       url('/assets/webfonts/montserrat/Montserrat-Regular.woff') format('woff'),
       url('/assets/webfonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
       url('/assets/webfonts/montserrat/Montserrat-Regular.svg#Montserrat') format('svg'),
       url('/assets/webfonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/webfonts/montserrat/Montserrat-Medium.eot');
  src: url('/assets/webfonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
       url('/assets/webfonts/montserrat/Montserrat-Medium.woff') format('woff'),
       url('/assets/webfonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
       url('/assets/webfonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg'),
       url('/assets/webfonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/webfonts/montserrat/Montserrat-Bold.eot');
  src: url('/assets/webfonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
       url('/assets/webfonts/montserrat/Montserrat-Bold.woff') format('woff'),
       url('/assets/webfonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
       url('/assets/webfonts/montserrat/Montserrat-Bold.svg#Montserrat') format('svg'),
       url('/assets/webfonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/webfonts/montserrat/Montserrat-Black.eot');
  src: url('/assets/webfonts/montserrat/Montserrat-Black.woff2') format('woff2'),
       url('/assets/webfonts/montserrat/Montserrat-Black.woff') format('woff'),
       url('/assets/webfonts/montserrat/Montserrat-Black.ttf') format('truetype'),
       url('/assets/webfonts/montserrat/Montserrat-Black.svg#Montserrat') format('svg'),
       url('/assets/webfonts/montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}
