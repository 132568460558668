@mixin break($minmax,$breakpoint) {
  @if $breakpoint == "s" {
    @media only screen and (#{$minmax}-width: map-get($breakpoints, $breakpoint)) {
       @content;
    }
  }
  @else if $breakpoint == "m" {
    @media only screen and (#{$minmax}-width: map-get($breakpoints, $breakpoint)) {
       @content;
    }
  }
  @else if $breakpoint == "l" { 
    @media only screen and (#{$minmax}-width: map-get($breakpoints, $breakpoint)) {
       @content;
    }
  }
  @else {
     @media only screen and (#{$minmax}-width: map-get($breakpoints, $breakpoint)){
      @content;
    }
  }
}
