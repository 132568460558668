.main-header {
    width: 100%;
    @include clearfix;
    @include break(min,s) {
        position: absolute;
        top: 0;
        left: 0;
        transition: background-color 0.3s ease-in-out;

        &.reset-top {
            top: auto;
        }

        &.follow {
            background-color: rgba(255,255,255,0.85);
            position: fixed;
            top: 0;
            border: 0;

            &:hover {
                background-color: rgba(255,255,255,1);
            }
        }
    }
    z-index: 10;
}

.main-nav {
    width: 60%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    background-color: rgba(255,255,255,0.95);
    padding: 3rem;
    box-sizing: border-box;
    transform: translate(100%,0);
    transition: transform 0.3s ease-in-out;

    &.reveal {
        transform: translate(0,0);
    }
    @include break(min,s) {
        background-color: transparent;
        transform: translate(0,0);
        position: static;
        width: 70rem;
        margin: 0 auto;
        @include clearfix;
        padding: {
            top: 3.5rem;
            bottom: 3.5rem;
            left: 0;
            right: 0;
        }
        transition: padding 0.3s ease-in-out;
    }
    @include break(min,m) {
        width: 96rem;
    }
    @include break(min,l) {
        width: 120rem;
    }

    &.follow {
        @include break(min,m) {
            padding: {
                top: 1.5rem;
                bottom: 1.5rem;
            }
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        @include break(min,s) {
            float: right;
            position: relative;
            top: 1rem;

            .follow & {
                top: 0.5rem;
            }
        }

        &-item {
            @include break(min,s) {
                float: left;
                margin: {
                    left: 3rem;
                }
            }
            font-family: $heading__type;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 1.1rem;
            line-height: 4rem;
        }

        &-link {
            text-decoration: none;
            color: inherit;
            cursor: pointer;

            &--button {
              text-decoration: none;
              color: inherit;
              cursor: pointer;
              }
            @include break(min,s) {
                border-bottom: 1px solid transparent;
                padding: {
                    bottom: 1rem;
                }
                transition: border-color 0.3s ease-in-out;

                .darken &,
                .moods &,
                .rights & {
                    color: #fff;
                }

                .darken .follow &,
                .moods .follow &,
                .rights .follow &  {
                    color: #555;
                }

                &:hover {
                    padding: {
                        bottom: 1rem;
                    }
                    border-color: #EC7C3C;
                }

                &.selected {
                    border: {
                        bottom: 2px solid #555;
                    }
                }

                &--button {
                    background-color: rgba(0,0,0,0.5);
                    color: #fff;
                    text-decoration: none;
                    position: relative;
                    cursor: pointer;
                    padding: 1.5rem 2.5rem;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background-color: rgba(0,0,0,0.8);
                    }
                }
            }
        }
    }

    &__logo {
        width: 100%;
        margin: {
            bottom: 3rem;
        }

        &__type {
            stroke: #000;
            fill: #1D1C1B;
        }

        .darken &__type,
        .moods &__type,
        .righs &__type {
            stroke: #fff;
            fill: #fff;
        }
        @include break(min,s) {
            width: auto;
            float: left;
            max-height: 5rem;
            margin: {
                bottom: 0;
            }

            .darken .follow &__type,
            .moods .follow &__type,
            .rights .follow &__type {
                stroke: #000;
                fill: #1D1C1B;
            }
        }
    }
}

.main-nav__menu-toggle {
    @include break(min,s) {
        display: none;
    }
    font-size: 1.8rem;
    padding: 0;
    box-sizing: border-box;
    line-height: 4.3rem;
    position: absolute;
    left: -6rem;
    top: 3rem;
    background-color: rgba(255,255,255,0.95);
    border: 0;
    -webkit-appearance: none;
    transition: all 0.3s ease-in-out;
    width: 4rem;
    height: 4rem;

    .reveal & {
        left: -4rem;
    }

    &:focus {
        outline: none;
    }

    span {
        display: none;
    }
}



.main-footer {
    position: relative;
    z-index: 10;
    //background-color: #1c1c1c;
    background: linear-gradient(to top, rgba(40, 40, 40, 1) 20%,rgba(40,40,40,0) 100%);
    color: #eee;
    text-align: center;
    padding: {
        top: 6rem;
        bottom: 6rem;
    }
    height: 35rem;
    box-sizing: border-box;
    background-position: top center;
    background-size: cover;

    &__home-link {
        @include break(min,s) {
            display: inline-block;
            width: 12rem;
            position: relative;
            // &::before
            // {
            //   content: "";
            //   width: 60vw;
            //   border:
            //   {
            //     top: 1px solid #e8e8e8;
            //   }
            //   display: block;
            //   height: 1px;
            //   position: absolute;
            //   left: 50%;
            //   top: 4.5rem;
            //   transform: translate(-50%,0);
            //   z-index: 1;
            // }
        }
    }

    &__home-image {
        &--roundel,
        &--type {
            max-width: 9rem;
            position: relative;
            z-index: 2;
            margin: {
                bottom: 3rem;
                //left: 1.5rem;
            }
        }

        &--type {
            max-width: 12rem;
        }
    }
}

.contact-details {
    font-family: $heading__type;
    font-size: 1.2rem;

    &__address {
        padding: {
            left: 1.5rem;
            right: 1.5rem;
        }
        display: inline-block;
        @include break(min,m) {
            display: block;
            width: auto;
            background-color: transparent;
        }
    }

    &__telephone-email {
        display: inline-block;
        padding: {
            left: 1.5rem;
            right: 1.5rem;
        }
        @include break(min,m) {
            display: block;
            background-color: transparent;
        }

        a
        {
          color: inherit;
        }
    }
}
